<template>
  <v-container>
    <h3>Gizlilik Politikası</h3>
    <p>
      Son güncellenme: 29/06/2022
      Güvenliğiniz bizim için önemli. Bu sebeple bizimle paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır.
      Biz, Straborn, veri sorumlusu olarak, bu gizlilik ve kişisel verilerin korunması politikası ile, hangi kişisel
      verilerinizin hangi amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği, veri işleme
      yöntemimiz ve hukuki sebeplerimiz ile; işlenen verilerinize ilişkin haklarınızın neler olduğu hususunda sizleri
      aydınlatmayı amaçlıyoruz.
    </p>
    <h3>Toplanan Kişisel Verileriniz, Toplanma Yöntemi ve Hukuki Sebebi</h3>
    <p>
      IP adresiniz ve kullanıcı aracısı bilgileriniz, sadece analiz yapmak amacıyla ve çerezler (cookies) vb.
      teknolojiler vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve bazen de analitik sağlayıcılar, reklam
      ağları, arama bilgi sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü taraflardan elde edilerek, kaydedilerek,
      depolanarak ve güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat
      işleme şartına dayanılarak işlenecektir.
    </p>
    <h3>Kişisel Verilerinizin İşlenme Amacı</h3>
    <p>
      Bizimle paylaştığınız kişisel verileriniz sadece analiz yapmak suretiyle; sunduğumuz hizmetlerin gerekliliklerini
      en iyi şekilde yerine getirebilmek, bu hizmetlere sizin tarafınızdan ulaşılabilmesini ve maksimum düzeyde
      faydalanılabilmesini sağlamak, hizmetlerimizi, ihtiyaçlarınız doğrultusunda geliştirebilmek ve sizleri daha geniş
      kapsamlı hizmet sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek ve kanundan doğan zorunlulukların
      (kişisel verilerin talep halinde adli ve idari makamlarla paylaşılması) yerine getirilebilmesi amacıyla, sözleşme
      ve hizmet süresince, amacına uygun ve ölçülü bir şekilde işlenecek ve güncellenecektir.
    </p>
    <h3>Toplanan Kişisel Verilerin Kimlere ve Hangi Amaçlarla Aktarılabileceği</h3>
    <p>
      Bizimle paylaştığınız kişisel verileriniz; faaliyetlerimizi yürütmek üzere hizmet aldığımız ve/veya verdiğimiz,
      sözleşmesel ilişki içerisinde bulunduğumuz, iş birliği yaptığımız, yurt içi ve yurt dışındaki 3. şahıslar ile
      kurum ve kuruluşlara ve talep halinde adli ve idari makamlara, gerekli teknik ve idari önlemler alınması koşulu
      ile aktarılabilecektir.
    </p>
    <h3>Kişisel Verileri İşlenen Kişi Olarak Haklarınız</h3>
    <p>
      KVKK madde 11 uyarınca herkes, veri sorumlusuna başvurarak aşağıdaki haklarını kullanabilir:
    </p>
    <p>
      Kişisel veri işlenip işlenmediğini öğrenme,
    </p>
    <p>
      Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
    </p>
    <p>
      Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
    </p>
    <p>
      Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
    </p>
    <p>
      Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
    </p>
    <p>
      Kişisel verilerin silinmesini veya yok edilmesini isteme,
    </p>
    <p>
      (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
      isteme,
    </p>
    <p>
      İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir
      sonucun ortaya çıkmasına itiraz etme,
    </p>
    <p>
      Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep
      etme, haklarına sahiptir.
    </p>
    <h3>İletişim</h3>
    <p>
      Sizlere hizmet sunabilmek amaçlı analizler yapabilmek için, sadece gerekli olan kişisel verilerinizin, işbu
      gizlilik ve kişisel verilerin işlenmesi politikası uyarınca işlenmesini, kabul edip etmemek hususunda tamamen
      özgürsünüz. Siteyi kullanmaya devam ettiğiniz takdirde kabul etmiş olduğunuz tarafımızca varsayılacak olup, daha
      ayrıntılı bilgi için bizimle info@straborn.com e-mail adresi üzerinden iletişime geçmekten lütfen çekinmeyiniz.
    </p>
  </v-container>
</template>
<script>
export default {}
</script>
<style lang="">

</style>