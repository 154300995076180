//const BaseApiUrl = "https://apptest.cagnaz.com/service/api"
const BaseApiUrl = "https://panel.straborn.com/service/api"
//const BaseUrl = "https://apptest.cagnaz.com";
const BaseUrl = "https://panel.straborn.com";
const ProductImages = BaseUrl+"/assets/productImage/"
const GalleryImages= BaseUrl+"/assets/photoGalleryImages/"
    
export default {
    BaseApiUrl: BaseApiUrl,
    ProductImages,
    GalleryImages,
}