<template>
  <v-container
    id="about"
    tag="section"
  >
    <base-subheading>Hikayemiz- Hakkımızda</base-subheading>

    <p>
      Straborn Coffee özgür ruha inanan kahve tutkunu bir ekip tarafından “…Ye, iç, neşelen!
       Çünkü diğer şeyler bunun kadar değmez.” Sözlerinin sahibi meşhur gezgin coğrafyacı Strabon dan esinlenerek açıldı.
       Straborn ismi ile de özgürlüğe ve gezginliğe atıf yapıldı. 
    </p>
    <p>
        Straborn Coffee macerayı,gezginliği ve keşfetmeyi seven insanların buluştuğu, uzun ve keyifli sohbetler eşliğinde kahvelerini yudumladıkları kafelere dönüşüyor. 
Bizler içimizdeki bu özgür ruhu ve kahve tutkusunu misafirlerimize aktarmak ve daha dinamik hale getirmek için uğraştık,
 nihayetinde Straborn Coffee doğdu.
  Nitelikli kahvelerimiz, pastalarımız ve konsept tasarımlarımız ile bir kültürü var etmeye hazır hale geldik.
   Tüm inancımız ve heyecanımızla bu keyfi yaşamaya sizleri de davet ediyoruz.
    “Çünkü kuşkusuz rastlantılar insanların düşüncesi ve hareketlerinde çok rol oynar”.
 Strabon..
    </p>
    <base-subheading>Marka Misyonu</base-subheading>
    <p>
      "Daha keyifli bir yaşam için güzel anılar ve ilham verici özlemler yaratmak" misyonuyla Straborn Coffee,
       mobil internet ve büyük veri teknolojisinin yeni perakende modelini tam olarak kullanmakta ve yüksek teknoloji ile derinlemesine iş birliği yapmaktadır.
       Çeşitli alanlarda kaliteli tedarikçileri ile yüksek kaliteli bir tüketici deneyimini müşterilerine sunmaktadır.
    </p>
    <p>
      Şu anda, Straborn Coffee,
       müşterilerine yüksek kaliteli ürünler sunmak için ülke çapında ödüllü Baristaları ile içecek, 
       yiyecek ve hızlı tüketim malları alanlarını kapsayan kendi ürün sistemini kurmuştur,
        teknoloji vasıtası ile de yüksek kolaylık sağlayan hizmetleri bir araya getirmiştir.
    </p>
  </v-container>
</template>

<script>
export default {
    name:'HomeAbout'

}
</script>

<style>

</style>