<template>
   
  <v-row class="mt-15 primary">
      <v-col cols="12" md="9" align="center" class="mt-15" >

     
          <base-subheading  class="white--text">Uygulamamızı İndirin</base-subheading>
          <p class="white--text"> 
              Hızlı Sipariş Verebilmek İçin Uygulamamızı İndirin
          </p>
          <v-row class="mt-10" justify="center">
              <img src="./../../assets/appStores/app-store.svg" alt="" width="250" class="mr-7" >
              <img src="./../../assets/appStores/google-play.svg" alt="" width="250" class="mr-7">
          </v-row>
          
            
      </v-col>
      <v-col cols="12" md="3" height="500">
          <v-img src="../../assets/appStores/iphone.png" contain  height="450"></v-img>
      </v-col>
  </v-row>
    
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>