<template>
    <v-container>
        <h2>MESAFELİ SATIŞ SÖZLEŞMESİ</h2>
        <h3>MADDE 1 - SÖZLEŞMENİN TARAFLARI</h3>
        <h3>SATICI</h3>
        <p>ÜNVANI : Domina Yemek Gıda Turizm Medikal Danışmanlık San. ve Tic. A.Ş.</p>
        <p>ADRES : Anıttepe Mah. G.M.K Bul. No: 103/12 Çankaya/Ankara</p>
        
        <p>WEB : www.straborn.com</p>
        <p>E-POSTA : info@straborn.com</p>
        <p>Bundan sonra SATICI olarak anılacaktır.</p>
        <h3>ALICI</h3>
        <p>ADI SOYADI / ÜNVANI : ......</p>
        <p>ADRES : ......</p>
        <p>TELEFON : ......</p>
        <p>E-POSTA : ......</p>
        <p>Bundan sonra ALICI olarak anılacaktır.</p>
        <h3>MADDE 2 - SÖZLEŞMENİN KONUSU</h3>
        <p>İş bu sözleşmenin konusu, ALICI'nın, SATICI'ya ait www.straborn.com web sitesinden elektronik ortamda veya TEL nolu telefondan sipariş verdiği,
                sözleşmede bahsi geçen nitelikleri haiz ve yine sözleşmede satış fiyatı belirtilen mal/hizmetin satışı ve teslimi ile ilgili olarak
                4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Uygulama Esas ve Usulleri Hakkında Yönetmelik hükümleri
                gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
                ALICI, satışa konu mal/hizmetlerin temel nitelikleri, satış fiyatı, ödeme sekli, teslimat koşulları vs. satışa konu mal/hizmet ile ilgili
                    tüm ön bilgiler ve "cayma" hakkı konusunda bilgi sahibi olduğunu, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında
                    mal/hizmeti sipariş verdiğini iş bu sözleşme hükümlerince kabul ve beyan eder. www.straborn.com sitesinde ödeme sayfasında yer alan 
                    ön bilgilendirme ve fatura iş bu sözleşmenin ayrılmaz parçalarıdır.</p>
        <h3>MADDE 3 - SÖZLEŞME TARİHİ</h3>
        <p>SATICI tarafından daha önce imzalanmış bulunan iş bu iki nüsha sözleşme, ALICI tarafından ...... 
            tarihinde imzalanarak kabul edilmiştir. Sözleşmenin bir nüshası ALICI'nın e-posta adresine mail gönderilecektir.</p>
        <h3>MADDE 4 - MAL/HİZMETİN TESLİMİ, SÖZLEŞMENİN İFA YERİ VE TESLİM ŞEKLİ</h3>
        <p>Mal/hizmet, ALICI'nın teslimini talep etmiş olduğu ...... adresinde ...... adlı kişi/kuruluşa teslim edilecektir.</p>
        <h3>MADDE 5 - TESLİMAT MASRAFLARI VE İFASI</h3>
        <p>Teslimat masrafları ALICI'ya aittir. SATICI,
                web sitesinde ilan ettiği rakamın üzerinde alışveriş yapanların teslimat ücretinin kendisince karşılanacağını beyan 
                etmişse, teslimat masrafı SATICI'ya aittir. Teslimat; stoğun müsait olması ve mal bedelinin SATICI'nın hesabına geçmesinden sonra en kısa
                sürede yapılır. SATICI, mal/hizmeti siparişinden itibaren 30 (otuz) gün içinde teslim eder ve bu süre içinde yazılı bildirimle 
                ek 10 (on) günlük süre uzatım hakkını saklı tutar. Herhangi bir nedenle mal/hizmet bedeli ödenmez veya banka kayıtlarında iptal edilir ise,
                SATICI mal/hizmetin teslimi yükümlülüğünden kurtulmuş kabul edilir.</p>
        <h3>MADDE 6 - ALICININ BEYAN VE TAAHHÜTLERİ</h3>
        <p>ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı
                mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir.
                Teslimden sonra mal/hizmetin özenle korunması borcu, ALICI'ya aittir. Fatura iade edilmelidir. 
                Mal/hizmetin tesliminden sonra ALICI'ya ait kredi kartının ALICI'nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız 
                veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşunun mal/hizmet bedelini SATICI'ya ödememesi halinde,
                ALICI kendisine teslim edilmiş olması kaydıyla mal/hizmeti 3 (üç) 
            gün içinde SATICI'ya göndermekle yükümlüdür. Bu takdirde teslimat giderleri ALICI'ya aittir.</p>
        <h3>MADDE 7 - SATICININ BEYAN VE TAAHHÜTLERİ</h3>
        <p>SATICI, sözleşme konusu mal/hizmetin sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım kılavuzları ile teslim edilmesinden sorumludur. Sözleşme konusu mal/hizmet, ALICI'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden SATICI sorumlu tutulamaz. SATICI, cayma beyanının kendisine ulaşmasından sonra 7 (yedi) gün içinde mal/hizmet bedelini, varsa kıymetli evrakı iade eder. Mal/hizmeti 20 (yirmi) gün içinde iade alır. Haklı gerekçelerle SATICI, sözleşmedeki ifa süresi dolmadan ALICI'ya eşit kalite ve fiyatta tedarik edebilir. SATICI mal/hizmetin ifasının imkânsızlaştığını düşünüyorsa, sözleşmenin ifa süresi dolmadan ALICI'ya bildirir. Ödenen bedel ve varsa belgeler 10 (on) gün içinde iade edilir. Garanti belgesi ile satılan mal/hizmetlerden olan veya olmayan mal/hizmetlerden arızalı veya bozuk olan mal/hizmetler, garanti şartları içinde gerekli onarımın yapılması için SATICI'ya gönderilebilir, bu takdirde teslimat giderleri SATICI tarafından karşılanacaktır.</p>
        <h3>MADDE 8 - SÖZLEŞMEYE KONU MAL/HİZMETİN ÖZELLİKLERİ</h3>
        <p>Mal/hizmetin Cinsi ve Türü, Miktarı, Marka/Modeli, Rengi ve Tüm Vergiler Dâhil Satış Bedeli www.straborn.com adlı web sitesindeki mal/hizmet tanıtım sayfasında yer alan bilgilerde ve iş bu sözleşmenin ayrılmaz parçası sayılan faturada belirtildiği gibidir.</p>
        <h3>MADDE 9 - MAL/HİZMETİN PEŞİN FİYATI</h3>
        <p>Mal/hizmetin peşin fiyatı sipariş sonu mail atılan örnek fatura ve ürün ile birlikte müşteriye gönderilen fatura içeriğinde mevcuttur.</p>
        <h3>MADDE 10 - VADELİ FİYAT</h3>
        <p>Mal/hizmetin satış fiyatına yapılan vadeye göre fiyatı sipariş sonu mail atılan örnek fatura ve ürün ile birlikte müşteriye gönderilen fatura içeriğinde mevcuttur.</p>
        <h3>MADDE 11 - PEŞİNAT TUTARI</h3>
        <p>Mal/hizmetin peşinat tutarı sipariş sonu mail atılan örnek fatura ve ürün ile birlikte müşteriye gönderilen fatura içeriğinde mevcuttur.</p>
        <h3>MADDE 12 - ÖDEME PLANI</h3>
        <p>ALICI'nın, kredi kartı ile ve taksitle alışveriş yapması durumunda siteden seçmiş olduğu taksit biçimi geçerlidir. Taksitlendirme işlemlerinde, ALICI ile kart sahibi banka arasında imzalanmış bulunan sözleşmenin ilgili hükümleri geçerlidir. Kredi kartı ödeme tarihi banka ile ALICI arasındaki sözleşme hükümlerince belirlenir. ALICI, ayrıca bankanın gönderdiği hesap özetinden taksit sayısını ve ödemelerini takip edebilir.</p>
        <h3>MADDE 13 - CAYMA HAKKI</h3>
        <p>ALICI, sözleşme konusu mal/hizmetin kendisine veya gösterdiği adresteki kişi/kuruluşa tesliminden itibaren 7 (yedi) gün içinde cayma hakkını kullanabilir. Cayma hakkının kullanılması için aynı süre içinde SATICI'ya faks, e-posta (bilgi@eticaretkur.com) veya 0 850 303 61 00 nolu telefon ile bildirimde bulunulması ve mal/hizmetin 14. madde hükümleri çerçevesinde ve iş bu sözleşmenin ayrılmaz parçası olan ve www.efortek.com.tr web sitesinde yayınlanmış olan önbilgiler gereğince, ambalaj ve içeriğinin denenirken hasar görmemiş olası şarttır. Bu hakkın kullanılması halinde, 3. kişiye veya ALICI'ya teslim edilen mal/hizmete ilişkin fatura aslının iadesi zorunludur. Cayma hakkına ilişkin ihbarın ulaşmasını takip eden 10 (on) gün içinde mal/hizmet bedeli ALICI'ya iade edilir ve 20 (yirmi) günlük süre içinde mal/hizmet iade alınır. Fatura aslı gönderilmezse, ALICI'ya KDV ve varsa diğer yasal yükümlülükler iade edilemez. Cayma hakkı nedeni ile iade edilen mal/hizmetin teslimat bedeli SATICI tarafından karşılanır.</p>
        <h3>MADDE 14 - CAYMA HAKKI KULLANILAMAYACAK MAL/HİZMETLER</h3>
        <p>Niteliği itibarıyla iade edilemeyecek mal/hizmetler, hızla bozulan ve son kullanma tarihi geçen mal/hizmetler, tek kullanımlık mal/hizmetler, web site hosting, web site yazılım kiralama, kopyalanabilir her türlü yazılım ve programlardır. Ayrıca, her türlü yazılım ve programların da, DVD, DIVX, VCD, CD, MD, videokasetlerde, bilgisayar ve kırtasiye sarf malzemelerinde (toner, kartuş, şerit v.b) ile kozmetik malzemelerinde cayma hakkının kullanılabilmesi için mal/hizmetin ambalajının açılmamış, bozulmamış ve kullanılmamış olmaları şartı bulunmaktadır.</p>
        <h3>MADDE 15 - TEMERRÜD HALİ VE HUKUKİ SONUÇLARI</h3>
        <p>ALICI, kredi kartı ile yapmış olduğu işlemlerinde temerrüde düşmesi halinde kart sahibi bankanın kendisi ile yapmış olduğu kredi kartı sözleşmesi çerçevesinde faiz ödeyecek ve bankaya karşı sorumlu olacaktır. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI'dan talep edebilir ve her koşulda ALICI'nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI'nın oluşan zarar ve ziyanını ödemeyi kabul eder.</p>
        <h3>MADDE 16 - YETKİLİ MAHKEME</h3>
        <p>İş bu sözleşmeden kaynaklanabilecek ihtilaflarda, Sanayi ve Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Hakem Heyetleri, bu değerin üzerindeki ihtilaflarda Tüketici Mahkemeleri yetkilidir. Tüketici Mahkemesi bulunamayan yerlerde Asliye Hukuk Mahkemeleri yetkilidir.</p>
        <p>SATICI : straborn.com</p>
        <p>ALICI : ......</p>
        <p>TARİH : ......</p>
    </v-container>
</template>
<script>
export default {
    
}
</script>
